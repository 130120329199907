<template>
  <header
    v-if="data && data.content"
    ref="globalHeader"
    class="global-header"
    :class="[
      isMenuTransitionDisabled ? 'global-nav--hide-transition' : '',
      isNavOpen ? 'global-nav--open' : '',
      isHeaderShy ? 'global-header--shy' : '',
      isHeaderHidden ? 'global-header--hidden' : '',
      isHeaderLightTheme ? 'global-header--light' : '',
    ]"
  >
    <a id="skip-navigation" href="#content">Skip Navigation</a>
    <nav ref="globalNav" class="global-nav">
      <div class="global-nav-logo-container">
        <NuxtLink
          aria-label="Go to ShiftKey home page"
          to="/"
          class="global-nav-logo-container__logo"
          @click="exitNav"
        >
          <ShiftKeyLogo v-if="isHeaderLightTheme || isHeaderShy" />
          <ShiftKeyLogoWhite v-else />
        </NuxtLink>
      </div>
      <button
        id="nav-get-started"
        ref="menuButton"
        :aria-expanded="isNavOpen"
        aria-controls="nav-list"
        class="global-nav-menu-button"
        type="button"
        @click.prevent="menuClick"
      >
        {{
          isNavOpen == false
            ? 'Open Global Navigation Menu'
            : 'Close Global Navigation Menu'
        }}
      </button>
      <ul id="nav-list" class="global-nav-list">
        <li v-for="navLink in data.content.globalnav_links" :key="navLink._uid">
          <div class="dropdown-wrapper">
            <div
              v-if="navLink.link.cached_url"
              :id="navLink.id ? navLink.id : undefined"
              class="global-nav-list__link global-nav-list__parent-link"
              @click="exitNav"
            >
              <span
                v-if="navLink.display_name"
                class="global-nav-list__link-text"
                >{{ navLink.display_name }}</span
              >
              <span v-else class="global-nav-list__link-text">{{
                navLink.link.story.name
              }}</span>
              <span v-if="navLink.sub_links" class="chev-span">
                &nbsp;<svg
                  class="w-2 inline-block nav-chev transition-all duration-200 transform"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 10"
                  aria-hidden="true"
                >
                  <path
                    d="M15 1.2l-7 7-7-7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <ul v-if="navLink.sub_links" class="dropdown-menu">
              <li v-for="sub_link in navLink.sub_links" :key="sub_link._uid">
                <NuxtLink
                  v-if="sub_link.link.cached_url"
                  :id="sub_link.id ? sub_link.id : undefined"
                  :to="
                    sub_link.link.linktype === 'url'
                      ? sub_link.link.cached_url
                      : '/' + sub_link.link.cached_url
                  "
                  :rel="
                    sub_link.link.target === '_blank'
                      ? 'nofollow noopener'
                      : undefined
                  "
                  :target="
                    sub_link.link.target === '_blank'
                      ? sub_link.link.target
                      : ''
                  "
                  class="dropdown-menu__link"
                  :class="sub_link.preload ? sub_link.preload : ''"
                  @click="exitNav"
                >
                  <span
                    v-if="sub_link.display_name"
                    class="dropdown-menu__link"
                    >{{ sub_link.display_name }}</span
                  >
                  <span v-else class="dropdown-menu__link">{{
                    sub_link.link.story.name
                  }}</span>
                </NuxtLink>
              </li>
            </ul>
          </div>
          <NavCollapseable class="collapseable" :blok="navLink" />
        </li>
        <li
          v-if="loginLink && loginLink.link.cached_url"
          class="global-nav-list__login-mobile"
        >
          <NuxtLink
            :id="loginLink.id ? loginLink.id : undefined"
            :to="
              loginLink.link.linktype === 'url'
                ? loginLink.link.cached_url
                : '/' + loginLink.link.cached_url
            "
            rel=""
            :target="
              loginLink.link.target === '_blank' ? loginLink.link.target : ''
            "
            class="global-nav-list__link"
          >
            {{ loginLink.display_name }}
          </NuxtLink>
        </li>
        <div
          v-for="enhancedNavLink in data.content.globalnav_enhanced_links"
          :key="enhancedNavLink._uid"
        >
          <NuxtLink
            v-if="enhancedNavLink.link.cached_url"
            :id="enhancedNavLink.id ? enhancedNavLink.id : undefined"
            :to="
              enhancedNavLink.link.linktype === 'url'
                ? enhancedNavLink.link.cached_url
                : '/' + enhancedNavLink.link.cached_url
            "
            :rel="
              enhancedNavLink.link.target === '_blank'
                ? 'nofollow noopener'
                : undefined
            "
            :target="
              enhancedNavLink.link.target === '_blank'
                ? enhancedNavLink.link.target
                : ''
            "
            class="global-nav-list__link"
          >
            <li class="global-nav-list__enhanced-link">
              <h2 class="eyebrow">
                {{ enhancedNavLink.eyebrow }}
              </h2>

              <div class="global-nav-list__enhanced-link-name">
                {{ enhancedNavLink.display_name }}
              </div>
            </li></NuxtLink
          >
        </div>
      </ul>
      <div
        v-if="loginLink && loginLink.link.cached_url"
        class="global-nav-utlity"
      >
        <NuxtLink
          v-if="loginLink.link.cached_url"
          :id="loginLink.id ? loginLink.id : undefined"
          :to="
            loginLink.link.linktype === 'url'
              ? loginLink.link.cached_url
              : '/' + loginLink.link.cached_url
          "
          rel=""
          :target="
            loginLink.link.target === '_blank' ? loginLink.link.target : ''
          "
          class="global-nav-list__link"
        >
          {{ loginLink.display_name }}
        </NuxtLink>
        <CTADropdown
          v-if="ctaDropdown"
          :blok="ctaDropdown"
          :islighttheme="isHeaderLightTheme || isHeaderShy"
          align="right"
        />
      </div>
      <button
        aria-hidden="true"
        class="global-nav__trap-focus"
        @focus="trapFocus"
      />
    </nav>
    <div v-for="banner in data.content.banners" :key="banner._uid">
      <Banner :blok="banner" :slug="props.slug" />
    </div>
  </header>
</template>

<script lang="ts" setup>
import NavCollapseable from '../components/NavCollapseable.vue';
import { useStoriesStore } from '@/stores/Stories';
import { GlobalHeaderProps, ThemeProps } from '@/types';

// Props
const props = defineProps<{
  theme: ThemeProps;
  slug: string;
}>();

const globalHeader = ref<HTMLHeadElement>();
const menuButton = ref<HTMLButtonElement | null>(null);

const isNavOpen = ref(false);
const isHeaderShy = ref(false);
const isHeaderHidden = ref(false);
const isMenuTransitionDisabled = ref(false);
const isHeaderLightTheme = computed(() => props.theme === 'chalk');

const store = useStoriesStore();
await store.fetchData();
// @ts-ignore
const data: GlobalHeaderProps | undefined = store.getGlobalHeader;
const loginLink =
  data && data.content.globalnav_login
    ? data.content.globalnav_login[0]
    : undefined;
const ctaDropdown =
  data && data.content.globalnav_cta_dropdown
    ? data.content.globalnav_cta_dropdown[0]
    : undefined;

const exitNav = () => {
  isNavOpen.value = false;
};

const resetHeader = () => {
  isNavOpen.value = false;

  // Prevents mobile nav from flashing on screen size change
  isMenuTransitionDisabled.value = true;

  setTimeout(() => {
    isMenuTransitionDisabled.value = false;
  }, 1000);
};

const menuClick = () => {
  isNavOpen.value = !isNavOpen.value;
};

const handleShyNavBehavior = () => {
  let lastScrollTop = 0;

  document.addEventListener('scroll', () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (isNavOpen.value !== true) {
      if (window.scrollY === 0) {
        isHeaderShy.value = false;
        isHeaderHidden.value = false;
      } else if (scrollTop > lastScrollTop) {
        isHeaderHidden.value = true;
      } else if (scrollTop < lastScrollTop) {
        isHeaderShy.value = true;
        isHeaderHidden.value = false;
      }
    }

    lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    lastScrollTop = window.scrollY;
  });
};

const handleResize = () => {
  const mediaQuery = window.matchMedia('(min-width: 1025px)');

  if (mediaQuery.addEventListener) {
    mediaQuery.addEventListener('change', () => {
      resetHeader();
    });
  }
};

const handleEscapeKey = () => {
  document.addEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape' && isNavOpen.value === true) {
      exitNav();
    }
  });
};

const trapFocus = () => {
  // Only run if screen size is smaller than 1024 and nav is open
  if (window.matchMedia('(max-width: 1024px)') && isNavOpen.value) {
    menuButton.value?.focus();
  }
};

onMounted(() => {
  resetHeader();
  handleResize();
  handleShyNavBehavior();
  handleEscapeKey();
  trapFocus();
});
</script>

<style lang="scss">
#skip-navigation {
  align-items: center;
  background-color: var(--darkest);
  border: 1px solid var(--lightest);
  color: var(--lightest);
  display: flex;
  font-size: 0.875rem;
  inset-block-start: 0;
  inset-inline-start: 0;
  justify-content: center;
  margin-block-start: 20px;
  overflow: hidden;
  padding: 12px 24px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  transform: translateX(-102%);
  transition: transform var(--timing) var(--easing-1);
  z-index: 5;

  &:active,
  &:focus-visible {
    outline: none;
    transform: translateX(0);
  }
}

.global-header {
  --line-color: var(--lightest);

  --shy-nav-speed: 250ms;
  --shy-nav-color-speed: calc(var(--shy-nav-speed) / 2);

  background-color: transparent;
  color: var(--lightest);
  height: var(--header-height);
  inset-block-start: 0;
  position: fixed;
  transition: top var(--shy-nav-speed),
    background-color var(--shy-nav-color-speed),
    color var(--shy-nav-color-speed);
  width: 100%;
  z-index: 4;

  .shiftkey-logo {
    &__text {
      transition: fill var(--timing), opacity var(--timing);
    }
  }

  .cta-dropdown__button {
    background-color: #0abc28;
    border: 1px solid #0abc28;
    padding-block: 16px;
    padding-inline: 20px;
  }

  &--shy {
    --line-color: var(--darkest);
    --current-header-height: var(--header-height-shy);

    background-color: var(--lightest);
    color: var(--darkest);
    height: var(--header-height-shy);

    .shiftkey-logo {
      &__text {
        fill: var(--darkest);
      }
    }
  }

  &--light {
    --line-color: var(--darkest);

    color: var(--darkest);

    .shiftkey-logo {
      &__text {
        fill: var(--darkest);
      }
    }
  }

  &--hidden {
    inset-block-start: -100%;
  }
}

.global-nav-logo-container {
  background-color: transparent;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-start;
  transition: background-color var(--timing) var(--easing-1);

  &__logo {
    &:focus-visible {
      outline: 1px solid var(--line-color);
      outline-offset: 4px;
    }

    @media all and #{$mq-m-max} {
      z-index: 4;
    }
  }
}

.global-nav-list {
  display: flex;
  gap: 24px;
  margin-block: 0;
  z-index: 1;

  li {
    @media all and #{$mq-m-max} {
      padding-inline: var(--side-spacing);
    }
  }

  .router-link-active {
    @media all and #{$mq-l-min} {
      border-bottom: 1px solid;
      padding-bottom: 2px;
    }
    @media all and #{$mq-m-max} {
      text-decoration: underline;
      text-underline-offset: 6px;
    }
  }

  &__link {
    font-size: 0.875rem;
    text-decoration: none;

    @media all and #{$mq-m-max} {
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }
    }

    &:focus-visible {
      outline: 1px solid var(--line-color);
      outline-offset: 4px;
    }

    @media all and #{$mq-m-max} {
      font-family: var(--mint-extra-bold), -apple-system, system-ui,
        BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif;
      font-size: 1.4375rem;
    }
  }
  &__link-text {
    font-size: 0.875rem;
    text-decoration: none;
    cursor: default;
    @media all and #{$mq-m-max} {
      font-size: 1.4375rem;
    }
  }
  &__enhanced-link {
    background-image: url('/img/ui/arrow-right.svg');
    background-position-x: calc(100% - var(--side-spacing));
    background-position-y: bottom;
    background-repeat: no-repeat;
    margin-block-start: var(--spacing-s);

    @media all and #{$mq-l-min} {
      display: none;
    }
  }

  &__enhanced-link-name {
    text-decoration: underline;
    text-underline-offset: 6px;
  }

  .eyebrow {
    --max-font: 12;
    --min-font: 12;

    color: var(--bright-green);
    margin-block-end: 12px;
    text-decoration: none !important;
  }

  &__login-mobile {
    @media all and #{$mq-l-min} {
      display: none;
    }
  }

  @media all and #{$mq-m-max} {
    background-color: var(--lightest);
    color: var(--darkest);
    flex-direction: column;
    gap: 16px;
    height: 100vh;
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 0;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    padding-block: calc(20px + var(--header-height)) 40px;
    padding-inline: 0;
    position: absolute;
    transition: background-color var(--timing) var(--easing-1),
      opacity var(--timing) var(--easing-1),
      transform var(--timing) var(--easing-1),
      visibility var(--timing) var(--easing-1);
    transition-delay: 300ms, 300ms, 0ms, 300ms;
    visibility: hidden;
    width: 100%;
  }

  // targets first enhanced nav li
  li:not(.global-nav-list__enhanced-link) + .global-nav-list__enhanced-link {
    border-block-start: 1px solid #d2d3d4;
    margin-block-start: var(--spacing-xl);
    padding-block-start: 40px;
  }
}

.global-nav {
  @include container;

  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-block: 0;
  position: relative;

  &--open {
    --line-color: var(--darkest);

    .global-nav-logo-container {
      align-items: center;
      background-color: var(--lightest);
      height: 100%;
      transition-delay: var(--timing);
      z-index: 4;
    }

    .shiftkey-logo {
      &__text {
        opacity: 0;
      }
    }

    .global-nav-list {
      opacity: 1;
      transform: translateX(0);
      transition-delay: 0ms;
      visibility: visible;
    }

    .global-nav-list li {
      opacity: 1;
      transition-delay: 325ms, 0ms;
      visibility: visible;
    }
  }

  &--hide-transition {
    .global-nav-list {
      transition: none;
    }

    .global-nav-logo-container {
      transition: none;
    }
  }
}

.global-nav-menu-button {
  --line-width: 24px;
  --menu-button-timing: 350ms;
  --menu-button-width: 24px;
  --menu-button-height: 24px;

  background-image: linear-gradient(
    90deg,
    var(--line-color),
    var(--line-color)
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: var(--line-width) 2px;
  display: flex;
  flex-shrink: 0;
  font-size: 0;
  height: var(--menu-button-height);
  inset-inline-end: 0;
  margin-block-start: calc(
    (var(--current-header-height) / 2) - (var(--menu-button-height) / 2)
  );
  margin-inline-end: var(--side-spacing);
  overflow: hidden;
  position: absolute;
  text-indent: 100%;
  width: var(--menu-button-width);
  z-index: 4;

  &::before,
  &::after {
    background-color: var(--line-color);
    content: '';
    display: block;
    height: 2px;
    inset-inline-start: calc(
      (var(--menu-button-width) - var(--line-width)) / 2
    );
    margin-block: 0;
    margin-inline: 0;
    position: absolute;
    transition-delay: 0ms, 0ms;
    transition-duration: var(--menu-button-timing), var(--menu-button-timing);
    width: var(--line-width);
    will-change: transform;
  }

  &::before {
    inset-block-start: calc(50% - 7px);
    transition-property: top, transform;
  }

  &::after {
    inset-block-end: calc(50% - 7px);
    transition-property: bottom, transform;
  }

  &:focus-visible {
    outline: 1px solid var(--line-color);
    outline-offset: 4px;
  }

  @media all and #{$mq-l-min} {
    display: none;
  }
}

.global-nav-menu-button[aria-expanded='true'] {
  background-image: linear-gradient(90deg, transparent, transparent);
}

.global-nav-menu-button[aria-expanded='true']::before {
  inset-block-start: calc(50% - 1px);
  transform: rotate(45deg);
  transition-delay: 0ms, var(--timing);
}

.global-nav-menu-button[aria-expanded='true']::after {
  inset-block-end: calc(50% - 1px);
  transform: rotate(-45deg);
  transition-delay: 0ms, var(--timing);
}

.global-nav-utlity {
  display: none;

  @media all and #{$mq-l-min} {
    align-items: center;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    gap: 20px;
    justify-content: flex-end;
  }
}

@media all and #{$mq-l-min} {
  .global-nav__trap-focus {
    display: none;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 999;
  display: none;
  padding: 0.5rem;
  margin: 0;
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px 2px 16px 16px;
  width: 201px;

  .router-link-active {
    @media all and #{$mq-l-min} {
      border-bottom: none;
      text-decoration: underline;
      text-underline-offset: 6px;
      padding-bottom: 5px;
    }
  }
}

.dropdown-menu__link {
  display: block;
  padding: 5px;
  color: #333;
  font-size: 0.875rem;
  text-decoration: none;
  width: 100%;
}

.dropdown-menu__link:hover {
  color: var(--bright-green);
  text-decoration: underline;
  text-underline-offset: 6px;
}

.dropdown-wrapper {
  height: 31px;
  overflow: hidden;
}

.dropdown-wrapper:hover {
  @media all and #{$mq-l-min} {
    .global-nav-list__parent-link {
      border-bottom: 1px solid;
      margin-bottom: -1px;
    }
  }
}

.global-nav-list .dropdown-wrapper:hover .dropdown-menu,
.global-nav-list .dropdown-wrapper .dropdown-menu:hover {
  display: block;
}
.dropdown-wrapper:hover {
  .nav-chev {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

.dropdown-wrapper:hover {
  .nav-chev {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media all and #{$mq-m-max} {
  .nav-chev {
    width: 0.75rem;
    padding-bottom: 0.33rem;
  }

  .chev-span {
    display: none;
  }

  .dropdown-wrapper {
    overflow: visible;
    height: auto;
    @media all and #{$mq-m-max} {
      display: none;
    }
  }
  .dropdown-menu {
    display: block;
    position: static;
    padding: 5px;
    padding-left: 1.5rem;
    padding-bottom: 0;
    box-shadow: none;
    border-radius: unset;
    background-color: transparent;
    width: 100%;

    li {
      padding: 0;
      margin: 0;
      list-style-type: disc;
    }
  }

  .dropdown-menu__link {
    font-size: 1.2375rem;
    font-family: var(--mint-extra-bold), -apple-system, system-ui,
      BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      sans-serif;
    padding: 5px;
    padding-left: 0;
  }
}

.collapseable {
  display: none;

  @media all and #{$mq-m-max} {
    display: block;
  }
}
</style>
